<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Read Aloud
                    <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'readaloud_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion & questId == 0">
            <h4 class="text-center mb-4 fancy"><span>Read Aloud</span>
            </h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">Text will reflect on screen. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud. </p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading and speaking</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 6 - 7 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The recording status box displays a countdown (ranges between 30 to 40 seconds) until the microphone opens. You will hear a short tone and start speaking immediately after the tone. </li>
                <li>Don't speak before the microphone starts because your voice won’t be recorded. Speak clearly, and don’t make any hurry. You’ll be able to record your answer only once. </li>
                <li>Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed”. </li>
                <li>You will be judged for reading, speaking, oral fluency, pronunciation and content.</li>
                <li>Use punctuations to help you decide where to pause when you read. Also stress the words that carry important information.</li>
            </ul>
            </template>
        </div>
        <!-- <div class="questionList col-sx-12 col-sm-12 d-sm-block d-sx-block d-md-none" v-show="!selectedquestion & questId == 0">
            <app-IntervalList ref="OneTimeintervalList" :questionLists="questionLists" :questionId="'readaloud_id'"/>
        </div> --> 
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
        <b-overlay class="fixedHeight" :show="show" rounded="sm" >
            <h4>Read Aloud
                <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
            </h4>
            <p class="questHeading">Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.</p>
            <!-- <audio style="height:0px!important;position:absolute!important;" id="readAloudSrc" v-if="readAloudSrc!=null" :src="readAloudSrc" /> -->
            <button @click="readPassage()" class="readPassage d-none d-md-block">
                <span v-if="!isReading">start read </span>
                <span v-if="isReading">Stop read </span>
                <b-icon v-if="isReading"  shift-v="1.5" scale="1.5" icon="volume-up-fill" style="padding-left:2px;"></b-icon>
                <b-icon v-if="!isReading" shift-v="1.5" scale="1.5" icon="volume-down-fill" style="padding-left:2px;"></b-icon>
            </button>
            
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" v-if="!startFlag"><p>{{title}} </p><app-Timer ref="timer"/></div>
                <!-- <b-button @click="changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-1 pr-1 col-sx-12 col-sm-12 d-sm-block d-sx-block d-md-none">Next</b-button>
                <b-button :disabled="currentQuestion <= 1" @click="changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-1 pr-1 col-sx-12 col-sm-12 d-sm-block d-sx-block d-md-none">Prev</b-button>
                 -->
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.readaloud_id" :idName="'readaloud_id'" :tableName="'tblReadAloud'"/>
            </div>
            </div>
            <p class="questdesc" v-on:dblclick="getSelectionText()">{{selectedquestion.readaloud_text}}</p>
            
            <!-- <p>{{timeLeft}}</p>
            <b-button @click="pauseTime()">Try Pause</b-button>
            <b-button @click="resumeTime()">Try Resume</b-button> -->
            <p class="recordMessage">{{recordMessage}}</p>
            <div class="record-settings">
                
              <!-- <vue-record-audio :mode="recordMode" ref="recording" @stream="onStream" @result="onResult" /> -->
               <audio-recorder :format="format"
                    :mic-failed="micCheck"
                    :before-recording="beforeRecording"
                    :after-recording="afterRecording"
                    :pause-recording="pauseRecording"
                    :src=null
                    :time=".666"
                    />
            </div>
            
            <div v-for="(record, index) in recordings" :key="index" class="row recorded-item">
                <div  v-if="questId == 0" class="audio-container col-12 left"><audio :src="record.src" controls /></div> 
                <div class="col-12"><p style="padding-left:0px!important">AI Scoring is available after submission.</p></div>
                <!-- <button @click="checkAnswer = true">Transcript</button> -->
            </div>
            
            <div v-if=" userAnswerResponse!=''  & questId == 0">
                <h4 class="mb-3">Transcript
                </h4>
                
                <p>{{userAnswerResponse}}</p>
            </div>
        </b-overlay>
             <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 mb-2">
                    <b-button :disabled="recordingBlob == null || checkAutoNext" class="mr-2 pl-4 pr-4" variant="outline-info" @click="showAnswerV9Marking()">Submit</b-button>
                    <!-- <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="showAnswerNew()">Submit</b-button> -->
                    <b-button :disabled="checkAutoNext" variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <b-button :disabled="apiResults ==null || checkAutoNext" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <span  > | 
                  <toggle-button :width="100" :height="28" v-model="checkAutoNext" :value="false" :color="{checked: '#368f5b', unchecked: '#cae8d6', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Auto-Next', unchecked: 'Manual-Next'}"/>
                </span>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5 ">
                    <div class="row">
                        <div class="col-md-2 col-sm-6 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div> 
                        <div class="col-md-2 col-sm-6 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 text-center mt-5">
                    <div class="col-12 mb-2 mt-2 results text-left" v-if="checkAnswer == true">
                    <h5>Result:- </h5>
                    <p><strong>Your results {{contentMarks}} out of 5</strong></p>
                    </div>
                    
                </div> -->
            </div>
        </div>
        <b-modal id="result-Modal" ref="result-Modal" size="lg" centered hide-footer title="ReadAloud AI Result">
         <b-container class="resultModel" >
               <app-ResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button v-if="!checkAutoNext" class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
         <b-button v-if="checkAutoNext" class="mt-3" variant="outline-dark" left >Disappear in {{ closeCounter }} seconds</b-button>
       </b-modal>
       <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="selectedquestion.readaloud_text" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'readaloud_id'"/></b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserReadAloudRecording'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import $ from "jquery";
import ResultPresent from '../reuseable/resultPresent'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
//import blobToBase64 from 'blob-to-base64'
const sdk = require("microsoft-cognitiveservices-speech-sdk");
import IntervalList from '../reuseable/intervalList'
import DiscussionTab from '../reuseable/discussionTab'
import QuestionStatus from '../reuseable/questStatus'
import {bus} from '../../main'
export default {
    props:{
        questId:{
            type: Number,
            default: 0,
            required: false
        }
    },
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-ResultPresent':ResultPresent,
        'app-dictTemp': dictTemplate,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            format:'wav',
            selectedquestion: null,
            recordMode:'press',
            recordings: [],
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false,
            title: 'Prepare',
            show:false,
            userAnswer:{
                readaloud_id : 0,
                user_id : 0,
                user_recording: null,
                url:'',
                keywords:'',
                confidenceLevel:0,
                transcript:'',
                mock_test_id:0,
                user_result:''
            },
            userAnswerResponse: '',
            currentUserId:0,
            checkAnswer:false,
            recordStop:false,
            actualAnswer:'',
            contentMarks:5,
            user_type:'Free',
            currentQuestion:0,
            isReading:false,
            readAloudSrc:null,
            recordMessage:'Click to Start',
            apiResults:null,
            resultStatus:'AI-Result',
            recordingBlob:null,
            isShowModal: false,
            modelTitle:'heading',
            aud:null,
            searchKey:'',
            searchQuestionList:null,
            i:30,
            currentUser:null,
            threeSecondIntervalStop:false,
            microsoftTranscript:'',
            recognizer:null,
            currentIndex:0,
            closeCounter:5,
            closeTime:null,
            checkAutoNext:false
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'speakmodule/fetchQuestion',
            getTranscripty:'speakmodule/getTranscripty',
            saveAnswer: 'speakmodule/saveAnswer',
            generateFileUpload:'speakmodule/generateFileUpload',
            fetchUserUsage:'auth/saveDashBoardData',
        }),
        ...mapGetters({
            getQuestionList: 'speakmodule/questions',
            answerResponse: 'speakmodule/AnswerResponse',
            userDetail: 'auth/user',
            GetUserUsageResponse:'auth/getDashBoardResponse'
        }),
        getSelectionText() 
        {
            var text = "";
            if (window.getSelection) {
                text = window.getSelection().toString();
            } else if (document.selection && document.selection.type != "Control") {
                text = document.selection.createRange().text;
            }
            //console.log(text)
            this.synthesizeSpeech(text)
        },
        readPassage(){
            //var aud = document.getElementById("readAloudSrc");
            
            if(this.isReading)
            {
                this.isReading = false
                this.aud.pause()
                $('.readPassage').removeClass('play');
                this.$refs.timer.resumeTime()
            }
            else{
                this.aud = document.getElementById("readAloudAudio");
                this.aud.src=this.readAloudSrc
                this.isReading =true
                this.aud.play();
                 $('.readPassage').addClass('play');
                 this.$refs.timer.pauseTime()
            }
           
        },
        showModal() {
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        synthesizeSpeech(selectedText) {
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
            synthesizer.speakTextAsync(
                selectedText,
                result => {
                    // Interact with the audio ArrayBuffer data
                    const audioData = result.audioData;
                    //console.log(`Audio data byte size: ${audioData.byteLength}.`)

                    synthesizer.close();
                },
                error => {
                    //console.log(error);
                    synthesizer.close();
                });
        },
        resetParams(){
            this.apiResults=null
            this.recordingBlob = null
            this.resultStatus='AI-Result'
            this.show=false
            this.recordMessage='Click to Start'
            this.checkAnswer = false
            this.isReading= false
            $('.readPassage').removeClass('play');
            this.contentMarks = 5
            this.userAnswer = {
                readaloud_id : 0,
                user_id : 0,
                user_recording: null,
                url:'',
                keywords:'',
                confidenceLevel:0,
                mock_test_id:0,
                user_result:''
            }
            this.userAnswerResponse =''
            this.recordStop= false
            this.readAloudSrc=null
            this.microsoftTranscript=''
        },
        generateAudioFile(){
            if(this.selectedquestion.audio_path == null | this.selectedquestion.audio_path ==''){
                const body={
                    id:this.selectedquestion.readaloud_id,
                    text:this.selectedquestion.readaloud_text,
                    url:'configreadaloud/generateAudioUpdatePath'
                }
                 this.saveAnswer(body).then(()=>{
                        //console.log(this.answerResponse().data)
                        this.readAloudSrc=this.answerResponse().data

                        // var aud = document.getElementById("readAloudSrc");
                        // //console.log(aud)
                        // aud.play()
                      })
            }
            else{
                //console.log(this.selectedquestion.audio_path)
                this.readAloudSrc=this.selectedquestion.audio_path
            }
        },
        redoQuest(){
            this.getQuestionDetail(this.selectedquestion.readaloud_id)
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].readaloud_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        myLoop() {   
            var vm=this      //  create a loop function
            setTimeout(function() {   //  call a 30s setTimeout when the loop is called
                //console.log(vm.i); 
                vm.selectedquestion = vm.questionLists[vm.i]
                vm.generateAudioFile() 
                vm.i++;                    //  increment the counter
                if (vm.i < vm.questionLists.length) {           //  if the counter < 10, call the loop function
                    vm.myLoop();             //  ..  again which will trigger another 
                }                       //  ..  setTimeout()
            }, 60000)
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.readaloud_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        changeQuestion(change){
            var quest=0
            //currentIndex=this.questionLists.indexOf(this.selectedquestion);
            if(change==='next'){
                if(this.currentIndex<this.questionLists.length-1){
                    quest=this.questionLists[this.currentUserId+1].readaloud_id
                    this.getQuestionDetail(quest)
                }
            }
            else{
                if(this.currentIndex>0){
                    quest=this.questionLists[this.currentUserId-1].readaloud_id
                    this.getQuestionDetail(quest)
                }
            }
        },
        getQuestionDetail(questionId){
            //this.selectedquestion=null
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            var vm = this
            this.resetParams()
            this.userAnswer.user_id=this.currentUserId
            this.selectedquestion = this.questionLists.filter(function (item) {
                
                return item.readaloud_id == questionId;
            })[0]
            this.currentIndex=this.questionLists.indexOf(this.selectedquestion);
            vm.userAnswer.readaloud_id = questionId
            //console.log(vm.userAnswer.readaloud_id)
        if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            this.modelTitle = this.selectedquestion.title
            this.readAloudSrc= this.selectedquestion.audio_path
            //this.myLoop()
            //this.generateAudioFile()
            //console.log(this.selectedquestion)
            //this.actualAnswer = this.selectedquestion.transcript
            this.recordings = []
            this.startFlag = false
            this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)
            this.$refs.timer.setTime(40)
            this.title = 'Prepare'
            $('.ar-recorder__stop').click()
            })
        },
        removeRecord () {
            ////console.log(this.state)
        },
        userUsage(){
        var data={
            user_id: this.currentUser.user_id,
            url:'users/userUsageCount'
          }
          this.fetchUserUsage(data).then(()=>{
            ////console.log(this.GetUserUsageResponse())
           this.currentUser.speaking_Counter= this.GetUserUsageResponse().data.speakingCount
            //this.$ls.remove('PackageType')
          })
        },
        checkUserAccess(){
            const user =this.currentUser
            //console.log(user.speaking_Counter)
            if(user.user_type == 'Free' & user.speaking_Counter>=5)
            {
                return false
            }
            else if(user.user_type == 'standard' & user.speaking_Counter>=10)
            {
                return false
            }
            else{
                this.currentUser.speaking_Counter++
                return true
            }
        },
        showAnswer(){
            try{
                
            if(!this.checkUserAccess())
                {
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserReadAloud--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.readaloud_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            const filePath='audio/UserReadAloud/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
             var param={
                user_audio_file: filePath.replace('audio/',''),
                text: this.selectedquestion.readaloud_text,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.readaloud_id,
                url:'speechace/transcript'
            }
            ////console.log(param)
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.getTranscripty(param).then(()=>{
                                //console.log(this.answerResponse().data)
                                ////debugger
                                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    this.userAnswer.url='userreadaloud/createuserreadaloud'
                                    //console.log(this.answerResponse().data.result)
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    const textArray=this.selectedquestion.readaloud_text.split(' ')
                                    const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    var content=0;
                                    //console.log(res.text_score.word_score_list)
                                    //as the content must be from 90
                                    if(correctWords>=totalWords*.85) content=90
                                    else if(correctWords>=totalWords*.65) content=78
                                    else if(correctWords>=totalWords*.55) content=67
                                    else if(correctWords>=totalWords*.45) content=50
                                    else if(correctWords>=totalWords*.35) content=35
                                    else if(correctWords>=totalWords*.25) content=25
                                    else if(correctWords>=totalWords*.15) content=15
                                    else if(correctWords>=totalWords*.10) content=10
                                    else content=0
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',// need to generate
                                        quality_Score:res.text_score.quality_score,// need to calculate
                                        total_Words:totalWords,
                                        correct_Words:correctWords,
                                        vocab:res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        pte_Estimate:res.text_score.fluency.overall_metrics.pte_estimate,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:this.selectedquestion.readaloud_text,
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.mock_test_id=0
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    //this.$refs['result-Modal'].show()
                                    //console.log(this.userAnswer)
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
            })
            }
            catch(error){
                //console.log(error)
                this.show=false
            }
        },
        showAnswerV9(){
            try{
                
            if(!this.checkUserAccess())
                {
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserReadAloud--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.readaloud_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            const filePath='audio/UserReadAloud/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
             var param={
                user_audio_file: filePath.replace('audio/',''),
                text: this.selectedquestion.readaloud_text,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.readaloud_id,
                url:'speechace/transcriptNew'
            }
            ////console.log(param)
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.getTranscripty(param).then(()=>{
                                //console.log("NEW Transcript Information:  ")
                                //console.log(this.answerResponse().data)
                                ////debugger
                                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    this.userAnswer.url='userreadaloud/createuserreadaloud'
                                    //console.log(this.answerResponse().data.result)
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    const textArray=this.selectedquestion.readaloud_text.split(' ')
                                    const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    var content=0;
                                    //console.log(res.text_score.word_score_list)
                                    //as the content must be from 90
                                    if(correctWords==totalWords) content=90
                                    else if(correctWords>=totalWords*.85) content=79
                                    else if(correctWords>=totalWords*.65) content=73
                                    else if(correctWords>=totalWords*.55) content=65
                                    else if(correctWords>=totalWords*.45) content=50
                                    else if(correctWords>=totalWords*.35) content=35
                                    else if(correctWords>=totalWords*.25) content=25
                                    else if(correctWords>=totalWords*.15) content=15
                                    else if(correctWords>=totalWords*.10) content=10
                                    else content=0
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',// need to generate
                                        quality_Score:res.text_score.pte_score.overall,// need to calculate
                                        total_Words:totalWords,
                                        correct_Words:correctWords,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:this.selectedquestion.readaloud_text,
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.mock_test_id=0
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    //this.$refs['result-Modal'].show()
                                    //console.log(this.userAnswer)
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
            })
            }
            catch(error){
                //console.log(error)
                this.show=false
            }
        },
        wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
                end = new Date().getTime();
            }
        },
        showAnswerV9Marking(){
            try{
                
            if(!this.checkUserAccess())
                {
                    this.checkAnswerFlag=false;
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserReadAloud--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.readaloud_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            const filePath='audio/UserReadAloud/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
             var param={
                user_audio_file: filePath.replace('audio/',''),
                text: this.selectedquestion.readaloud_text,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.readaloud_id,
                url:'speechace/transcriptNewMarking',
                questType:'ReadAloud',
                keyWords:[],
                questionTranscript:this.selectedquestion.readaloud_text
            }
            this.generateFileUpload(fd).then(()=>{
                if(this.answerResponse()=="Success"){
                    if(this.microsoftTranscript == '') this.wait(5000)
                    if(this.microsoftTranscript == ''){
                        this.resultStatus='No-Result'
                                this.show=false
                                return this.$alert('No voice found in submitted recording, Please retry.')
                    }
                            this.getTranscripty(param).then(()=>{
                                
                                if(this.answerResponse().data==undefined){
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                                }
                                else if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    this.userAnswer.url='userreadaloud/createuserreadaloud'
                                    //console.log(this.answerResponse().data.result)
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result;
                                    console.log(this.answerResponse());
                                    const markingResult=this.answerResponse().data.apiResults;
                                    // const textArray=this.selectedquestion.readaloud_text.split(' ')
                                    // const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    // const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    // var content=0;

                                    // if(correctWords==totalWords) content=90
                                    // else if(correctWords>=totalWords*.85) content=79
                                    // else if(correctWords>=totalWords*.65) content=73
                                    // else if(correctWords>=totalWords*.55) content=65
                                    // else if(correctWords>=totalWords*.45) content=50
                                    // else if(correctWords>=totalWords*.35) content=35
                                    // else if(correctWords>=totalWords*.25) content=25
                                    // else if(correctWords>=totalWords*.15) content=15
                                    // else if(correctWords>=totalWords*.10) content=10
                                    // else content=0
                                    
                                    
                                    // this.apiResults={
                                    //     transcript:'',// need to generate
                                    //     quality_Score:res.text_score.pte_score.overall,// need to calculate
                                    //     total_Words:totalWords,
                                    //     correct_Words:correctWords,
                                    //     vocab:res.text_score.pte_score.vocab,
                                    //     pte_Estimate:res.text_score.pte_score.fluency,
                                    //     word_Score_List:res.text_score.word_score_list,
                                    //     content_Score:content,
                                    //     textArray:textArray,
                                    //     text:this.selectedquestion.readaloud_text,
                                    //     src:filePath,
                                    //     type:'all'
                                    // }
                                    
                                    // var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    // var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                             
                                    var overAllScore =markingResult.overAllScore; 
                                    this.apiResults=markingResult;
                                    this.userAnswer.mock_test_id=0
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    if(this.checkAutoNext) {
                                        this.closeTime=null;
                                        this.closeCounter=5
                                        this.$refs['result-Modal'].show();
                                        this.startTime(); 
                                    }
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
            })
            }
            catch(error){
                //console.log(error)
                this.resultStatus='No-Result'
                this.show=false
                return this.$alert('No recording found, Please retry.')
            }
        },
        getIndicesOf(searchStr, str, caseSensitive) {
            var searchStrLen = searchStr.length;
            if (searchStrLen == 0) {
                return [];
            }
            var startIndex = 0, index, indices = [];
            for (index = 0; index < str.length; index++) {
                let element = str[index];
                if (!caseSensitive) {
                    element = element.toLowerCase();
                    searchStr = searchStr.toLowerCase();
                }
                if (element==searchStr) {
                    indices.push(index);
                    startIndex = index ;
                }
            }
            
            return indices;
        },
        showAnswerNew(){
            try{
                
            if(!this.checkUserAccess())
                {
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.show=true
            this.resultStatus='In-Process'
            const fileName='UserReadAloud--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.readaloud_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            const filePath='audio/UserReadAloud/'+fileName.replace('--','')
             this.userAnswer.user_recording = filePath
             var param={
                user_audio_file:filePath.replace('audio/',''),//"UserReadAloud/UserReadAloudu5_q853_track1667527243174.wav",// filePath.replace('audio/',''),
                text: this.selectedquestion.readaloud_text,
                user_id: this.userAnswer.user_id,
                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.readaloud_id,
                url:'deepgram/deepgramTranscript'
            }
            ////console.log(param)
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.getTranscripty(param).then(()=>{
                                //console.log(this.answerResponse().data)
                                ////debugger
                                var deepGramTranscriptJson=this.answerResponse().data.result.results.channels[0].alternatives[0];
                                var deepGramUtterancesJson=this.answerResponse().data.result.results.utterances
                               //console.log(deepGramUtterancesJson)
                                
                                if(this.answerResponse().data.success==1)
                                {
                                    // if(this.answerResponse().data.result.status!=200){
                                    //     this.resultStatus='No-Result'
                                    //     this.show=false
                                    //     return this.$alert('No recording found, Please retry.')
                                        
                                    // }
                                    this.userAnswer.url='userreadaloud/createuserreadaloud'
                                    //console.log(deepGramTranscriptJson)
                                    const res=this.userAnswer.transcript=deepGramTranscriptJson.transcript;
                                    var listWords=deepGramTranscriptJson.words;
                                    //console.log(listWords);
                                    var contCorrectWords=0;
                                    var contWrongWords=0;
                                    var pteEstimate=0;
                                    var questionText=this.selectedquestion.readaloud_text;
                                    var actualIndex=0;
                                    $.each(listWords.reverse(),(index,item)=>{
                                    if(questionText.indexOf(item.punctuated_word)!=-1) {
                                        contCorrectWords++;
                                        pteEstimate+=item.confidence;
                                    }
                                    else {
                                        
                                        contWrongWords++;
                                        listWords[actualIndex].confidence=0;
                                        pteEstimate-=item.confidence*3;
                                    }
                                    actualIndex++;
                                    });
                                    //console.log(listWords);
                                    const textArray=this.selectedquestion.readaloud_text.split(' ')
                                    const correctWords=(contCorrectWords-contWrongWords>1)?(contCorrectWords-contWrongWords):0 ; //res.text_score.fluency.overall_metrics.correct_word_count
                                    const totalWords=textArray.length //res.text_score.fluency.overall_metrics.word_count
                                    var content=0;
                                   
                                    ////console.log(res.text_score.word_score_list)
                                    //as the content must be from 90
                                    if((correctWords/totalWords)>=.95) content=90
                                    else if((correctWords/totalWords)>=.80) content=78
                                    else if((correctWords/totalWords)>=.75) content=67
                                    else if((correctWords/totalWords)>=.65) content=50
                                    else if((correctWords/totalWords)>=.35) content=35
                                    else if((correctWords/totalWords)>=.25) content=25
                                    else if((correctWords/totalWords)>=.15) content=15
                                    else if((correctWords/totalWords)>=.10) content=10
                                    else content=0
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',// need to generate
                                        quality_Score: (contCorrectWords/(contCorrectWords+contWrongWords)*100).toFixed(0),//res.text_score.quality_score,// need to calculate
                                        total_Words:totalWords,
                                        correct_Words:correctWords,
                                        vocab:pteEstimate, //res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        pte_Estimate: pteEstimate, //res.text_score.fluency.overall_metrics.pte_estimate,
                                        word_Score_List:listWords.reverse(),
                                        content_Score:content,
                                        textArray:textArray,
                                        text:this.selectedquestion.readaloud_text,
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)

                                    const wordCount=this.apiResults.word_Score_List.length
                                    let questionTextArray=this.apiResults.textArray;
                                    if(wordCount!=null)
                                    {
                                        
                                        let selectedWordIndex=0;
                                        
                                        let index = 0;
                                        while ( index < wordCount) {
                                            
                                            let element = this.apiResults.word_Score_List[selectedWordIndex];
                                            let word=this.apiResults.textArray[index];
                                            let confidence=0;
                                            let arry=this.getIndicesOf(element.punctuated_word,questionTextArray,true);
                                            if(arry.length>0){
                                                if($.inArray(index,arry)>=0){
                                                    confidence=element.confidence
                                                    //word=element.punctuated_word
                                                    selectedWordIndex++;
                                                }
                                                else{
                                                    confidence=0;
                                                }
                                            }
                                            else{
                                                confidence=0;
                                                selectedWordIndex++;
                                                //Incase of missing content words, we will deduct 2.5 for each work
                                                
                                            }

                                            if(confidence==0){
                                                this.apiResults.content_Score=((this.apiResults.content_Score-1)>0)?this.apiResults.content_Score-1:0;
                                                pteEstimate-=1*1;
                                                contWrongWords++;
                                            }
                                            index++;
                                        }

                                        this.apiResults.quality_Score= (contCorrectWords/(contCorrectWords+contWrongWords)*100).toFixed(0)
                                        if(this.apiResults.quality_Score<0)this.apiResults.quality_Score=0;
                                        pteEstimate=Math.round((pteEstimate/totalWords)*100)-10;// actutal number is 100 and we required
                                        this.apiResults.pte_Estimate=pteEstimate;
                                    }


                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.mock_test_id=0
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    //this.$refs['result-Modal'].show()
                                    //console.log(this.userAnswer)
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                else{
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                }
                                
                            })
                        }
            })
            }
            catch(error){
                //console.log(error)
                this.show=false
            }
        },
        startTime()
        {
            //var tt;
            if(this.closeCounter==5 && this.closeTime==null){
                this.closeTime=setInterval(()=>{this.startTime()},1000);
                console.log(this.closeCounter)
            }
            else if(this.closeCounter == 0) {
                console.log(this.closeCounter)
                clearInterval(this.closeTime);
                this.$refs['result-Modal'].hide();
                this.$refs.intervalList.changeQuestion('next');
            } else {
                console.log(this.closeCounter)
            this.closeCounter--;
            }
        },
        timerEnd(){
            //////console.log(this.$refs)
            //this.$refs.recording.stream(new MediaStream)
           // this.$listeners.onStream()
           if(!this.startFlag) $('.ar-icon.ar-icon__lg').click()
           this.title = 'Time'
            //alert('Timer')
        },
        playBeep(){
            var sound = document.getElementById("beepAudio");
            sound.playbackRate = 0.5;
            sound.play()
        },
        beforeRecording (msg) {
            this.userAnswerResponse =''
            //console.log('Before Record: ', msg)
            this.startFlag = false
            if(this.$refs.timer!=undefined)this.$refs.timer.setTime(0)
            this.recordings= []
            this.checkAnswer = false
            this.playBeep()
            this.title = 'Time'
            this.recordStop = false
            this.fromMic();
            this.recordMessage='Click to Stop'
            $('.ar-recorder__stop').addClass('upFront')
            
        },
        pauseRecording(){
            $('.ar-recorder__stop').click()
        },
        afterRecording(msg){
            this.recognizer.stopContinuousRecognitionAsync();
            this.recordMessage='Done'
            //this.startFlag = true
            this.checkAnswer = false
            this.recordStop = true
            this.userAnswer.transcript=this.userAnswerResponse
            //////console.log('After Record: ', msg)
            this.recordings=[]
            this.recordings.push({
            src: window.URL.createObjectURL(msg.blob)
            })
            //////console.log(msg.blob)
            //var vm = this
            this.userAnswer.user_recording = null
            this.$refs.timer.pauseTime()
            this.recordingBlob = msg.blob
            $('.ar-recorder__stop').removeClass('upFront')
            if(this.checkAutoNext) this.showAnswerV9Marking();
            
        },
        micCheck(msg){
            alert(msg)
        },
        checkRecordingPause(){
            if(this.recordMessage!='Done')
                {
                let myVar = setInterval(function() {
                    
                    if(this.microsoftTranscript!==this.microsoftTranscript & this.recordMessage!='Done')
                    {
                        //console.log('Inside Interval')
                        this.checkRecordingPause()
                    }
                    else{
                        //console.log('Interval stop')
                        clearInterval(myVar);
                        $('.ar-recorder__stop').click()
                    }
                }, 3000);
            }
        },
        fromMic() {
            //console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            //speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "3000");
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('1) Speak into your microphone.');


            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                    //console.log('2) Speak into your microphone.');
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`);
                }
            };

            this.recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                        //console.log('3) Speak into your microphone.');
                     this.microsoftTranscript +=e.result.text;
                     //this.checkRecordingPause()
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                        //console.log('4) Speak into your microphone.');
                        this.pauseRecording()
                        //console.log("NOMATCH: Speech could not be recognized.");
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log('5) Speak into your microphone.');
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log('6) Speak into your microphone.');
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log('7) Speak into your microphone.');
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
            
        }
    },
    beforeMount(){
        bus.$emit('checkMicPermission');
        if(this.questId === 0)
        {
            debugger
            this.questionLists=(this.$ls.get('tblReadAloud')!=null)?JSON.parse(this.$ls.get('tblReadAloud')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                var userdata =this.$ls.get('user')
                this.currentUserId= userdata.user_id
                this.user_type = userdata.user_type
                this.currentUser=userdata
                this.userUsage()
            }
            else{
                this.questionservice('configreadaloud/getallreadalouds').then(()=>{
                var getQuestionList = this.getQuestionList();
                // if(getQuestionList.data.success == 0){
                //     return this.$refs.navBar.signOut();
                // }
                this.questionLists = getQuestionList.data
                //var userdata = LocalStorageService.getUser()
                var userdata =this.$ls.get('user')
                this.currentUserId= userdata.user_id
                this.user_type = userdata.user_type
                this.currentUser=userdata
                this.userUsage()
                })
            }
        }
    },
}

</script>
<style scoped>
.questdesc 
{
    font-size: 17px;
}
</style>